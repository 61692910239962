.mainContainer {
  width: 100%;
  height: 100vh;
  background-image: url("../../assets/aboutBG.svg");
  background-size: fill;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}

.description {
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.description p {
  text-align: center;
  font-size: 1.5em;
  color: #fff;
  font-size: 23;
  width: 70%;
  line-height: 33px;
}

.second {
  margin-top: -20px;
}

@media screen and (max-width: 1480px) {
  .description {
    font-size: 1em;
    margin-left: 2em;
    margin-right: 2em;
    width: 500px;
    text-align: center;
  }

  .second {
    margin-top: 0px;
  }
}
