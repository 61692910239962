.container {
  margin: 3em;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 30px;
  display: flex;
  flex-direction: row;
  flex: 1;
}

img:hover {
  cursor: pointer;
}

.description {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hover{
  cursor: pointer;
  text-decoration-line: underline;
}

.taskDescriptions {
  color: white;
  font-size: 1.25em;
  line-height: 1.3em;
}

@media screen and (max-width: 1488px) {
  .container {
    flex-direction: column;
    align-items: center;
    width: '90%';
  }
  .taskDescriptions {
    margin-bottom: 2em;
  }
  .description {
    width: auto;
  }
}

@media screen and (max-width: 450px) {
  .container {
    width: 95%;
  }
}
