.mainContainer {
  width: 100%;
  height: 100vh;
  background-image: url("../../assets/homeBG.svg");
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  display: flex;
  margin: 1em;
}

.imgContainer {
  background-image: url("../../assets/arnav.jpg");
  background-size: cover;
  background-position-x: center;
  background-position-y: top;
  height: 400px;
  width: 400px;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
}

.description {
  display: flex;
  flex-direction: row;
  /* background-color: red; */
  align-items: center;
  /* gap: 50%; */
}

.homeText h3 {
  color: honeydew;
  font-size: 3em;
  text-align: center;
  margin-bottom: 15px;
}

.homeText {
  padding-top: -1em;
  margin-left: 25em;
}

.homeText p {
  color: honeydew;
  font-size: 1.5em;
  text-align: center;
  margin-bottom: 0px;
  padding-bottom: 0px;
  margin-top: 0px;
  padding-top: 0px;
}

@media screen and (max-width: 1480px) {
  .description {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .imgContainer {
    width: 200px;
    height: 200px;
  }

  .homeText {
    padding-top: 0;
    margin-left: 0em;
  }

  .homeText h3 {
    font-size: 2em;
  }

  .homeText p {
    font-size: 1.2em;
  }
}
