@font-face {
  font-family: "NotoSansBold";
  src: url("../../fonts/NotoSans/NotoSans-Bold.ttf");
}

.mainContainer {
  position: fixed;
  top: 30%;
  right: -85px;
  transition: 500ms;
  transition-timing-function: ease;
  z-index: 1000;
}

.mainContainerFocused {
  position: fixed;
  top: 30%;
  right: -85px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.6);
  transform: translateX(-80px);
  transition-timing-function: ease;
  transition: 500ms;
  z-index: 1000;
}

.menuContainer {
  padding-left: 20px;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.imageContainer img {
  height: 70px;
}

.menuContainer {
  margin-right: 20px;
}

.menuContainer p {
  /* color: #fff; */
  color: #001220;
  font-family: "NotoSansBold";
}

.menuContainer p:hover {
  transform: translateY(-2px);
  /* text-shadow: 5px 5px rgb(117, 114, 114); */
  text-shadow: 3px 3px rgba(117, 114, 255, 0.7);
}

@media screen and (max-width: 1048px) {
  .imageContainer img {
    height: 30px;
  }

  .mainContainer {
    position: fixed;
    top: 30%;
    right: -95px;
    transition: 500ms;
    transition-timing-function: ease;
    z-index: 1000;
  }
}
