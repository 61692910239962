@font-face {
  font-family: 'NotoSans';
  src: url('../../fonts/NotoSans/NotoSans-Regular.ttf');
  font-family: 'NotoSansBold';
  src: url('../../fonts/NotoSans/NotoSans-Bold.ttf');
}

.mainContainer {
  width: 100%;
  height: 100vh;
  background-image: url('../../assets/contactBg.svg');
  background-repeat: no-repeat;
  background-position: bottom;
  display: flex;
  flex-direction: row;
  padding-top: 3em;
}

.content {
  width: 100vw;
}

.getInTouch {
  font-family: 'NotoSansBold';
  font-size: 4em;
  color: #fff;
  margin-left: 2em;
}

.icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 3em;
}

.icons img {
  height: 125px;
  padding-right: 2em;
}

.bottomRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 15em;
}

.callMe,
.callMe img {
  height: 300px;
  padding-left: 6em;
  display: inline-block;
  border-radius: 50%;
}

@media screen and (max-width: 1337px) {
  .mainContainer {
    height: 100vh;
  }

  .content {
    height: 50vh;
  }

  .bottomRow {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5em;
  }

  .getInTouch {
    font-size: 3em;
  }

  .callMe {
    display: flex;
  }

  .callMe,
  .callMe img {
    padding-bottom: 3em;
    padding-left: 0em;
    height: 300px;
    border-radius: 50%;
  }

  .icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
    padding-right: 0em;
  }

  .icons img {
    height: 75px;
    padding-right: 1em;
    padding-left: 1em;
  }
}
