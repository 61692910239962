@font-face {
  font-family: 'NotoSansBold';
  src: url('../../fonts/NotoSans/NotoSans-Bold.ttf');
  /* font-family: ; */
}

.mainContainer {
  width: 100%;
  height: 100vh;
  background-image: url('../../assets/stackBG.svg');
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.title {
  font-size: 4em;
  font-family: 'NotoSansBold';
}

.imageGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  margin-left: 25em;
  gap: 1em;
}

.react {
  width: 100px;
  height: 100px;
  background-image: url('../../assets/react.svg');
  background-size: cover;
  background-position: center;
}

.flutter {
  width: 100px;
  height: 100px;
  background-image: url('../../assets/flutter.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transform: scale(0.7, 0.7);
}

.reactNative {
  width: 100px;
  height: 100px;
  background-image: url('../../assets/reactNative.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.java {
  width: 100px;
  height: 100px;
  background-image: url('../../assets/java.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.python {
  width: 100px;
  height: 100px;
  background-image: url('../../assets/python.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transform: scale(0.8, 0.8);
}

.dart {
  width: 100px;
  height: 100px;
  background-image: url('../../assets/dart.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transform: scale(1.6, 1.6);
}

.redux {
  width: 100px;
  height: 100px;
  background-image: url('../../assets/redux.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transform: scale(0.8, 0.8);
}
.javascript {
  width: 100px;
  height: 100px;
  background-image: url('../../assets/javascript.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transform: scale(0.7, 0.7);
}
.typescript {
  width: 100px;
  height: 100px;
  background-image: url('../../assets/typescript.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transform: scale(0.7, 0.7);
}
.expo {
  width: 100px;
  height: 100px;
  background-image: url('../../assets/logo-type-a-light.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transform: scale(0.7, 0.7);
}

@media screen and (max-width: 1448px) {
  .content {
    flex-direction: column;
  }

  .title {
    font-size: 4em;
    margin-bottom: 2em;
  }

  .imageGrid {
    margin-left: 0;
    gap: 1em;
  }
}
