@font-face {
  font-family: 'NotoSansBold';
  src: url('../../fonts/NotoSans/NotoSans-Bold.ttf');
  font-family: 'NotoSans';
  src: url('../../fonts/NotoSans/NotoSans-Regular.ttf');
}

.mainContainer {
  width: 100%;
  height: 100%;
  background-image: url('../../assets/projectsBG.svg');
  padding-top: 7em;
}

.heading {
  font-size: 4em;
  color: #fff;
  font-family: 'NotoSansBold';
  margin-bottom: 1em;
  margin-left: 2em;
}

.modal {
  width: 40vw;
  border: 1px solid #ccc;
  background: #001220;
  margin: 0 auto;
  margin-top: 20%;
  border-radius: 4px;
  border-width: 0px;
  border-color: none;
  color: #fff;
  animation-name: growbox;
  animation-duration: 0.5s;
}

@keyframes growbox {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0.2;
  }
  40% {
    opacity: 0.4;
  }
  60% {
    opacity: 0.6;
  }
  80% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}

.modalContent {
  padding: 20px;
}

.modalTitle {
  font-family: 'NotoSansBold';
  font-size: 2em;
}

.modalDescription p {
  text-align: justify;
  font-family: 'NotoSans';
  line-height: 22px;
  margin-top: 2em;
}

.modalDescription a {
  text-decoration: none;
  color: rgb(211, 133, 133);
}

.modalClose {
  position: relative;
  float: right;
  top: 0px;
  right: -4px;
  border-radius: 50%;
  padding: 5px;
  transition: all 200ms ease-in-out;
}

.modalClose:hover {
  cursor: pointer;
  transform: rotate(90deg);
}

.projectContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 3em;
  padding-right: 3em;
  padding-bottom: 1em;
  margin-top: 2em;
}

.project {
  margin-bottom: 2em;
  display: flex;
  width: 70%;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 3em;
}

.project a {
  text-decoration: none;
  color: #fff;
}

.susImages {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  z-index: 0;
}

.routinelyGrid {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  z-index: 0;
}

.greenswappGrid {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  z-index: 0;
}

.greenswapp1 {
  width: 650px;
  height: 400px;
  grid-column: 1 / span 8;
  grid-row: 1;
  z-index: 1;
}

.greenswapp2 {
  width: 250px;
  height: 445px;
  grid-column: 6 / span 8;
  grid-row: 1;
  z-index: 1;
  margin-top: 5%;
  border-radius: 10px;
}

.susImages:hover {
  cursor: pointer;
}

.sensoreeImages {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  z-index: 0;
}

.sensoreeImages:hover {
  cursor: pointer;
}

.routinelyImage img {
  width: 11em;
  height: 10em;
}
.routinelyImage:hover {
  cursor: pointer;
}

.sus1 {
  grid-column: 1 / span 8;
  grid-row: 1;
  z-index: 1;
}

.sens1 {
  grid-column: 3 / span 8;
  grid-row: 1;
  z-index: 1;
  margin-top: 5%;
}

.sus2 {
  grid-row: 1;
  grid-column: 3 / span 8;
  z-index: 2;
  margin-top: 5%;
}

.sus3 {
  grid-row: 1;
  grid-column: 5 / span 8;
  z-index: 3;
  margin-top: 17%;
}

.routinelyGrid img {
  width: 250px;
  height: 500px;
}

.routinely1 {
  grid-column: 1 / span 8;
  grid-row: 1;
  z-index: 1;
}

.routinely2 {
  grid-row: 1;
  grid-column: 3 / span 8;
  z-index: 2;
  margin-top: 5%;
}

.routinely3 {
  grid-row: 1;
  grid-column: 5 / span 8;
  z-index: 3;
  margin-top: 17%;
}

.susImages img {
  height: 500px;
  width: 280px;
  border-radius: 3%;
}

.sensoreeImages img {
  height: 380px;
  width: 500px;
  border-radius: 3%;
}

.description {
  display: flex;
  flex-direction: row;
  margin-left: 7em;
  color: #fff;
  font-family: 'NotoSans';
  font-size: 2em;
  text-align: left;
}

.description:hover {
  cursor: pointer;
}

.descriptionL {
  display: flex;
  flex-direction: row;
  color: #fff;
  font-family: 'NotoSans';
  font-size: 1.3em;
  margin-right: 5em;
}

.descriptionL:hover {
  cursor: pointer;
}

.moreInfo {
  position: relative;
  top: 5px;
  padding-left: 10px;
  color: rgb(66, 134, 161);
}

.moreInfoL {
  position: relative;
  top: 5px;
  padding-left: 10px;
  color: rgb(66, 134, 161);
}

.moreInfo:hover {
  cursor: pointer;
  color: black;
  transition: 500ms ease-in-out;
}

.moreInfoL:hover {
  cursor: pointer;
  color: #fff;
  transition: 500ms ease-in-out;
}

.description a {
  color: #002233;
}

.descriptionL {
  display: flex;
  flex-direction: column;
}

.descriptionL a {
  text-decoration: none;
  color: blue;
}

.image img {
  height: 600px;
  width: 600px;
  border-radius: 10%;
}

.image img:hover {
  cursor: pointer;
}

.wallpaper img {
  width: 650px;
  height: 450px;
  border-radius: 8%;
}

.description a {
  color: rgb(131, 35, 35);
}

.descriptionL a {
  color: rgb(131, 35, 35);
}

.toDoImages {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  z-index: 0;
  padding-right: 10em;
}

.toDoImages img {
  height: 400px;
  border-radius: 5%;
}

.toDo1 {
  grid-column: 1 / span 8;
  grid-row: 1;
  z-index: 1;
}

.toDo2 {
  grid-row: 1;
  grid-column: 2 / span 8;
  z-index: 2;
  margin-top: 8%;
}


/* .crispGrid {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  z-index: 0;
}

.crisp1 {
  /* width: 50%; */
/* } */

/* .crisp2 { */
  
/* } */

@media screen and (max-width: 1488px) {
  .heading {
    font-size: 3em;
    margin: 0 auto 2em auto;
    margin-left: 1em;
    text-align: left;
  }

  .mainContainer {
    background-position: center;
    padding-top: 3em;
  }

  .projectContainer {
    margin-top: 2em;
  }

  .project {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 3em;
    background-color: red;
  }

  .description {
    margin-left: 0em;
  }

  .susImages img {
    width: 15em;
    height: 30em;
  }

  .sensoreeImages img {
    height: 12em;
    width: 17em;
    border-radius: 3%;
  }

  .image img {
    width: 22em;
    height: 22em;
  }

  .wallpaper img {
    width: 550px;
    height: 350px;
  }

  .wallpaper:hover {
    cursor: pointer;
  }

  .description {
    margin-top: 1em;
    font-size: 1em;
    padding-left: 0px;
    padding-right: 0px;
    align-items: center;
    justify-content: center;
  }

  .descriptionL {
    margin-top: 1em;
    font-size: 4vw;
    align-items: center;
    justify-content: center;
    padding-left: 0px;
    padding-right: 0px;
  }

  .greenswappImage {
    width: 100px;
    height: 100px;
  }

  .greenswappImage img {
    width: 2em;
    height: 2em;
  }

  .greenswappImage:hover {
    cursor: pointer;
  }

  .moreInfo {
    top: 0px;
    color: skyblue;
  }

  .modal {
    width: 80vw;
  }

  .modalTitle {
    font-size: 1.5em;
  }

  .modalDescription p {
    text-align: left;
    font-size: 0.9em;
    font-family: 'NotoSansBold';
    font-weight: 700;
  }

  .wallpaper {
    margin-left: 0px;
  }
}

@media screen and (max-width: 450px) {
  .routinelyGrid img {
    width: 200px;
    height: 400px;
  }

  .greenswapp1 {
    width: 300px;
    height: 200px;
    grid-column: 1 / span 8;
    grid-row: 1;
    z-index: 1;
  }

  .greenswapp2 {
    width: 170px;
    height: 300px;
    grid-column: 4 / span 8;
    grid-row: 1;
    z-index: 1;
    margin-top: 7%;
    border-radius: 10px;
  }

  .susImages img {
    width: 10em;
    height: 20em;
  }

  .image img {
    width: 300px;
    height: 300px;
  }

  .wallpaper img {
    width: 250px;
    height: 150px;
  }
}
