@font-face {
  font-family: "NotoSansBold";
  src: url("../../fonts/NotoSans/NotoSans-Bold.ttf");
}

.navigationContainer {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 50;
}

.navigationContent {
  /* background-color: red; */
  display: flex;
  flex-direction: row;
  align-items: center;
}

.logoContainer img {
  height: 60px;
  padding: 1em;
  padding-left: 2em;
  cursor: pointer;
}

.links {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  padding-right: 100px;
  /* align-items: ; */
}

.linkText {
  display: inline-block;
  margin-left: 5em;
  cursor: pointer;
  transition-duration: 0.2s;
  color: #fff;
  font-size: 1em;
  line-height: 1.4em;
  font-family: "NotoSansBold";
}

.linkTextSecond {
  display: inline-block;
  margin-left: 5em;
  cursor: pointer;
  transition-duration: 0.2s;
  color: #fff;
  font-size: 1.3em;
  line-height: 1.4em;
  font-family: "NotoSansBold";
}

.linkText:hover {
  transform: translateY(-2px);
  text-shadow: 5px 5px rgb(26, 28, 107);
  /* color: red; */
}

.linkTextSecond:hover {
  transform: translateY(-2px);
  text-shadow: 5px 5px rgb(117, 114, 114);
  /* color: red; */
}

@media screen and (max-width: 1480px) {
  .links {
    padding-right: 20px;
  }
  .linkText {
    margin-left: 1em;
    font-size: 0.7em;
    line-height: 1em;
    /* color: red; */
  }

  .logoContainer img {
    height: 30px;
  }
}
